@charset "UTF-8";
/*提示字体颜色*/
/*弱提示背景色*/
/*圆角大小*/
/*间距8的倍数*/
/**
    flex布局
 */
/**
    flex布局
 */
/**
      文本布局
   */
/**
      flex布局上下左右居中
   */
/**
    文本布局
 */
/**
    渐变背景
 */
/*阴影*/
/* 超出显示省略号... */
.home-sider[data-v-12d02e1d] {
  width: 0.64rem !important;
  flex: 0 0 0.64rem !important;
  max-width: 0.64rem !important;
  min-width: 0.64rem !important;
}
[data-v-12d02e1d] .ant-layout-sider {
  background: var(--menu-bg);
}
.home-main-title[data-v-12d02e1d] {
  background: #ffffff;
}
