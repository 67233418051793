@charset "UTF-8";
/*提示字体颜色*/
/*弱提示背景色*/
/*圆角大小*/
/*间距8的倍数*/
/**
    flex布局
 */
/**
    flex布局
 */
/**
      文本布局
   */
/**
      flex布局上下左右居中
   */
/**
    文本布局
 */
/**
    渐变背景
 */
/*阴影*/
/* 超出显示省略号... */
.wd-login[data-v-37dfd6fc] {
  background: #499edd;
  background: linear-gradient(to right, #4a9ede, #69ded2);
  background: url(../img/login-back.6ff50581.png) no-repeat center center;
  background-size: cover;
}
.wd-login__wrap[data-v-37dfd6fc] {
    width: 12rem;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.wd-login__bg[data-v-37dfd6fc] {
    width: 7rem;
    height: 7rem;
    margin-right: 3rem;
}
.wd-login__form[data-v-37dfd6fc] {
    width: 3.36rem;
    height: 3.06rem;
    background: #fafafa;
    position: absolute;
    right: -1rem;
    border-radius: 0.05rem;
    padding: 0.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.wd-login__form .ant-form[data-v-37dfd6fc] {
      width: 100%;
}
.wd-login__form--logo[data-v-37dfd6fc] {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0.2rem;
}
.wd-login__form--logo img[data-v-37dfd6fc] {
        width: 0.4rem;
}
.wd-login__form--logo span[data-v-37dfd6fc] {
        font-size: 0.22rem;
        font-weight: 500;
        margin-left: 0.1rem;
}
