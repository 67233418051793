.redirect[data-v-85e0faf6] {
  padding: 0.1rem;
}
.redirect .redirect_div[data-v-85e0faf6] {
  color: #1890ff;
  cursor: pointer;
}
.redirect .radirect_text[data-v-85e0faf6] {
  margin-top: 0.1rem;
}
