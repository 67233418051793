@charset "UTF-8";
/*提示字体颜色*/
/*弱提示背景色*/
/*圆角大小*/
/*间距8的倍数*/
/**
    flex布局
 */
/**
    flex布局
 */
/**
      文本布局
   */
/**
      flex布局上下左右居中
   */
/**
    文本布局
 */
/**
    渐变背景
 */
/*阴影*/
/* 超出显示省略号... */
.notice-li[data-v-067d0834] {
  cursor: pointer;
  padding: 0.1rem 0;
  line-height: 0.22rem;
}
.notice-li .title[data-v-067d0834] {
    width: 100%;
    overflow: hidden;
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.notice-li .time[data-v-067d0834] {
    color: #aab7c2;
}
.no-data[data-v-067d0834] {
  text-align: center;
  color: #666;
}
