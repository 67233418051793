@charset "UTF-8";
/*提示字体颜色*/
/*弱提示背景色*/
/*圆角大小*/
/*间距8的倍数*/
/**
    flex布局
 */
/**
    flex布局
 */
/**
      文本布局
   */
/**
      flex布局上下左右居中
   */
/**
    文本布局
 */
/**
    渐变背景
 */
/*阴影*/
/* 超出显示省略号... */
.aisde-left-menu .children-menu-icon[data-v-ac6ac58c] {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.aisde-left-menu .app-logo[data-v-ac6ac58c] {
  height: 0.48rem !important;
}
.aisde-left-menu .app-logo img[data-v-ac6ac58c] {
    width: 0.25rem !important;
    height: 0.25rem !important;
}
.aisde-left-menu .app-li[data-v-ac6ac58c] {
  height: 0.64rem;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.aisde-left-menu .app-li .icon-sm[data-v-ac6ac58c],
  .aisde-left-menu .app-li img[data-v-ac6ac58c] {
    width: 0.17rem;
    height: 0.17rem;
    color: #ffffff;
}
.aisde-left-menu .app-li .active-menu-svg[data-v-ac6ac58c] {
    color: var(--menu-bg);
}
.aisde-left-menu .app-li .active-menu[data-v-ac6ac58c] {
    background: #fff;
    border-radius: 50%;
    border: 0.18rem solid var(--menu-bg);
}
.app-custom-tooltip .ant-tooltip-inner[data-v-ac6ac58c] {
  border-radius: 0.04rem;
  padding: 0.07rem 0.2rem;
}
.app-custom-tooltip .ant-tooltip-inner span[data-v-ac6ac58c] {
    font-size: 0.14rem;
    font-weight: 500;
    line-height: 0.26rem;
}
