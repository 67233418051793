@charset "UTF-8";
/*提示字体颜色*/
/*弱提示背景色*/
/*圆角大小*/
/*间距8的倍数*/
/**
    flex布局
 */
/**
    flex布局
 */
/**
      文本布局
   */
/**
      flex布局上下左右居中
   */
/**
    文本布局
 */
/**
    渐变背景
 */
/*阴影*/
/* 超出显示省略号... */
.fant-micro-main[data-v-5954443c] {
  min-width: 12rem !important;
}
.modular-data[data-v-5954443c] {
  padding: 0 0.24rem 0.24rem 0.24rem;
  overflow-y: auto;
}
.modular-data .home-modular[data-v-5954443c] {
    height: 3.36rem;
}
.modular-data .home-modular[data-v-5954443c] {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
.ant-layout .app-modular[data-v-5954443c] {
  margin: 0.16rem 0.24rem;
  height: 1.2rem;
  border-radius: 0.04rem;
  background: #fff;
}
.home-main-title[data-v-5954443c] {
  background: #ffffff;
}
.fant-micro-main[data-v-5954443c] {
  background: #F5F6F9;
}
.modular-data[data-v-5954443c] {
  margin-top: 0.16rem;
}
.home-modular-new[data-v-5954443c] {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.app-module[data-v-5954443c] {
  background: #fff;
  margin-bottom: 0.16rem;
}
.task-module[data-v-5954443c] {
  height: 2.8rem;
}
