@charset "UTF-8";
/*提示字体颜色*/
/*弱提示背景色*/
/*圆角大小*/
/*间距8的倍数*/
/**
    flex布局
 */
/**
    flex布局
 */
/**
      文本布局
   */
/**
      flex布局上下左右居中
   */
/**
    文本布局
 */
/**
    渐变背景
 */
/*阴影*/
/* 超出显示省略号... */
.fant-micro-title[data-v-64df070f] {
  width: 100%;
  height: 0.48rem;
  background: #fff;
  border-bottom: 1PX solid #F5F6F9;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  display: -moz-flex;
  display: -ms-flexbox;
  -moz-flex-direction: row;
  -o-flex-direction: row;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  -o-justify-content: space-between;
}
.fant-micro-title .logo-d[data-v-64df070f],
  .fant-micro-title .user-d[data-v-64df070f] {
    height: 0.48rem;
    line-height: 0.48rem;
}
.fant-micro-title .logo-d[data-v-64df070f] {
    cursor: pointer;
    padding-left: 0.24rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    display: -moz-flex;
    display: -ms-flexbox;
    -moz-flex-direction: row;
    -o-flex-direction: row;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    -ms-justify-content: flex-start;
    -o-justify-content: flex-start;
    -moz-align-items: center;
    -ms-align-items: center;
    -o-align-items: center;
    align-items: center;
}
.fant-micro-title .logo-d .modular-name[data-v-64df070f] {
      padding-left: 0.04rem;
      font-weight: 600;
      font-size: 0.16rem;
      color: #1b1d1f;
}
.fant-micro-title .logo-d img[data-v-64df070f] {
      display: inline-block;
      width: 0.22rem;
      height: 0.22rem;
      vertical-align: sub;
}
.fant-micro-title .logo-d .logo-d-label[data-v-64df070f] {
      line-height: 0.22rem;
      margin-left: 0.1rem;
      font-size: 0.16rem;
      font-weight: 600;
      color: #1b1d1f;
}
.fant-micro-title .title-nav-tag[data-v-64df070f] {
    flex: 1;
    padding: 0.14rem 0.1rem 0 0;
    overflow: hidden;
}
.fant-micro-title .user-d[data-v-64df070f] {
    padding-right: 0.24rem;
    color: #1b1d1f;
    font-weight: 400;
    line-height: 0.42rem;
}
.fant-micro-title .user-d .user-name[data-v-64df070f] {
      display: inline-block;
      margin: 0 0.08rem;
}
.fant-micro-title .user-d .acatar-sm[data-v-64df070f] {
      width: 0.24rem;
      height: 0.24rem;
}
.fant-micro-title .user-d .msg-remind[data-v-64df070f] {
      cursor: pointer;
      margin-right: 0.58rem;
}
.fant-micro-title .user-d .msg-remind .icon-sm[data-v-64df070f] {
        width: 0.22rem;
        height: 0.22rem;
        color: #1b1d1f;
}
.fant-micro-title .user-info-org[data-v-64df070f] {
    cursor: pointer;
}
.fant-micro-title .user-info-org .org-info-down[data-v-64df070f] {
      width: 0.12rem;
      color: #ffffff;
}
.fant-micro-title .swich-org[data-v-64df070f] {
    cursor: pointer;
    padding: 0.12rem 0.16rem;
    margin-bottom: 0;
}
.fant-micro-title .swich-org-title[data-v-64df070f] {
    margin: 0.07rem 0;
}
.fant-micro-title .swich-org[data-v-64df070f]:hover {
    background: #F5F6F9;
}
.fant-micro-title .org-info-down[data-v-64df070f] {
    width: 0.12rem;
    height: 0.12rem;
    position: relative;
    top: -0.04rem;
    left: -0.1rem;
}
