@charset "UTF-8";
/*提示字体颜色*/
/*弱提示背景色*/
/*圆角大小*/
/*间距8的倍数*/
/**
    flex布局
 */
/**
    flex布局
 */
/**
      文本布局
   */
/**
      flex布局上下左右居中
   */
/**
    文本布局
 */
/**
    渐变背景
 */
/*阴影*/
/* 超出显示省略号... */
.home-app[data-v-94ff21fe] {
  padding: 0.1rem;
  height: 1.15rem;
  box-shadow: 0 0.04rem 0.16rem rgba(122, 140, 153, 0.08);
}
.img[data-v-94ff21fe] {
  margin: 0 auto;
  width: 0.64rem;
  height: 0.64rem;
  overflow: hidden;
  border-radius: 0.16rem;
  cursor: pointer;
}
.img img[data-v-94ff21fe] {
    width: 100%;
    height: 100%;
}
.label[data-v-94ff21fe] {
  margin-top: 0.08rem;
  line-height: 0.22rem;
  height: 0.22rem;
  text-align: center;
  font-size: 0.14rem;
  font-weight: 400;
  cursor: pointer;
}
.app-item[data-v-94ff21fe] {
  height: 1rem;
}
.more-other img[data-v-94ff21fe] {
  padding: 0.1rem;
}
